import PropTypes from "prop-types";
import React from "react";
import "./styles.scss";

const Tags = {
    "how-to": "H",
    recipe: "R",
    equipment: "E",
    process: "P",
    news: "N",
    experiment: "X",
};

const Tag = props => {
    let value = Tags[props.children.toLowerCase()];
    return <div className="c-tag">{value}</div>;
};

const Article = props => (
    <a href={props.url} className="c-article">
        <article>
            <h2>
                <Tag>{props.tag}</Tag>
                {props.title}
            </h2>
            <p>{props.summary}</p>
            <small>{props.date}</small>
        </article>
    </a>
);

Article.propTypes = {
    title: PropTypes.string,
    tag: PropTypes.string,
    summary: PropTypes.string,
    date: PropTypes.string,
    url: PropTypes.string,
};

Article.defaultProps = {
    summary: "",
};

export default Article;
