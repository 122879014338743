import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

class LoadArticlesButton extends React.Component {
    click = event => {
        this.props.clickHandler(event);
    };

    render() {
        return (
            <div className="c-load-articles-button-container">
                <button className="c-load-articles-button" onClick={this.click}>
                    More
                </button>
            </div>
        );
    }
}

LoadArticlesButton.propTypes = {
    clickHandler: PropTypes.func,
};

export default LoadArticlesButton;
