import React from "react";
import "./styles.scss";

const ArticleBlock = props => (
    <section className="c-article-block">
        <h1>{props.date}</h1>
        {props.children}
    </section>
);

export default ArticleBlock;
